import { useEffect, useRef } from 'react';

import Analytics from '@hh.ru/analytics-js';
import responseSentElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/user_status/after_response/response_sent_element_shown';
import vacancyResponseCompleteExternal from '@hh.ru/analytics-js-events/build/xhh/applicant/vacancy/vacancy_response_complete';
import vacancyResponseCompleteGaExternal from '@hh.ru/analytics-js-events/build/xhh/applicant/vacancy/vacancy_response_complete_ga';
import vacancyTargetingResponseCompleteExternal from '@hh.ru/analytics-js-events/build/xhh/applicant/vacancy/vacancy_targeting_response_complete';
import BlokoLink, { LinkAppearance } from 'bloko/blocks/link';
import NotificationFooter from 'bloko/blocks/notificationManager/NotificationFooter';
import NotificationHeading from 'bloko/blocks/notificationManager/NotificationHeading';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import LocalStorageWrapper from 'bloko/common/storage/LocalStorageWrapper';
import { format } from 'bloko/common/trl';

import type { AddNotification } from 'lux/components/Notifications/Provider/types';
import { VacancyResponseResponseData } from 'lux/components/VacancyResponseLink/postVacancyResponsePopup';
import {
    CPA_STORE_FIELD_BY_VACANCY_AREA_ID,
    CPA_STORE_FIELD_BY_VACANCY_ID,
    YA_DIRECT_STORE_FIELD_BY_VACANCY_ID,
} from 'lux/components/VacancyTargetingViewer/const';
import { sendCpaLeadConversion } from 'lux/components/VacancyTargetingViewer/vacancyTargetingViewerUtils';
import translation from 'lux/components/translation';
import { AppDispatch } from 'lux/models/appDispatch';
import { ResponseStatusResume } from 'lux/models/applicantVacancyResponseStatuses';
import { PolitenessIndex } from 'lux/models/politenessIndex';
import { TopLevelSite } from 'lux/models/topLevelSite';
import fetcher from 'lux/modules/fetcher';
import {
    checkIsItemExistsInTemporaryStorage,
    checkIsItemExistsAndRemoveItInTemporaryStorage,
} from 'lux/utils/temporaryStorageUtils';

import jobSearchStatusAfterResponse from 'lux/components/VacancyResponseForm/JobSearchStatusNotification';
import resumeAutoHideNotification from 'lux/components/VacancyResponseForm/ResumeAutoHideNotification';
import VacancyQuestionNotification from 'lux/components/VacancyResponseForm/VacancyQuestionNotification';

const LOCALSTORAGE_KEY_FIRST = 'vacancy-response-first';

const TrlKeys = {
    header: 'vacancy.view.notify.response.title',
    responseStats: 'vacancy.view.notify.response.stats',
    responseChat: 'vacancy.view.notify.response.chat',
    openChatik: 'vacancy.view.response.openChat',
};

interface VacancyResponseNotificationProps {
    readPercent: number | null;
    topicId: string;
    vacancyId: number;
    openChatik: () => void;
}

type Label =
    | 'nonreq-letter-no-test-without-letter'
    | 'nonreq-letter-no-test-with-letter'
    | 'nonreq-letter-req-test-without-letter'
    | 'nonreq-letter-has-test-without-letter-with-test'
    | 'nonreq-letter-req-test-with-letter'
    | 'nonreq-letter-has-test-with-letter-with-test'
    | 'req-letter-no-test';

const VacancyResponseNotification: TranslatedComponent<VacancyResponseNotificationProps> = ({
    trls,
    readPercent,
    topicId,
    vacancyId,
    openChatik,
}) => {
    useEffect(() => LocalStorageWrapper.setItem(LOCALSTORAGE_KEY_FIRST, 'true'), []);
    const notificationRef = useRef(null);

    useEffect(() => {
        if (notificationRef.current) {
            responseSentElementShown(notificationRef.current, { vacancyId });
        }
    }, [vacancyId]);

    return (
        <>
            <NotificationHeading>{trls[TrlKeys.header]}</NotificationHeading>
            <div ref={notificationRef}>
                {readPercent && readPercent > 0
                    ? format(trls[TrlKeys.responseStats], { '{0}': readPercent })
                    : trls[TrlKeys.responseChat]}
            </div>
            {topicId !== 'SPAM' && (
                <NotificationFooter>
                    <BlokoLink
                        appearance={LinkAppearance.Pseudo}
                        onClick={openChatik}
                        data-qa="vacancy-response-notification__open-chat-link"
                    >
                        {trls[TrlKeys.openChatik]}
                    </BlokoLink>
                </NotificationFooter>
            )}
        </>
    );
};

export const vacancyResponse = {
    Element: translation(VacancyResponseNotification),
    kind: 'ok',
    autoClose: LocalStorageWrapper.getItem(LOCALSTORAGE_KEY_FIRST) !== null,
};

export const VACANCY_RESPONSE = 'VACANCY_RESPONSE';

interface CreateNotification {
    (props: {
        dispatch: AppDispatch;
        employerId?: number;
        data: VacancyResponseResponseData & {
            sendGAAnalyticsToStorage?: boolean;
            respondedWithResume?: ResponseStatusResume;
        };
        setResponseData?: (responseData: {
            readPercent: number | null;
            topicId: string;
            vacancyId: number;
            chatId?: number;
            hasResponseLetter: boolean;
        }) => void;
        publishedForResponseResumeHash: string | null;
        topLevelSite: TopLevelSite;
        addNotification: AddNotification;
        isQuestionResponse?: boolean;
        openChatik: () => void;
    }): void;
}

const EMPLOYER_STATS_URL = '/employer/stats';
declare global {
    interface FetcherGetApi {
        [EMPLOYER_STATS_URL]: {
            queryParams: { employerId: number };
            response: { stats: PolitenessIndex };
        };
    }
}

export const createNotification: CreateNotification = ({
    data,
    employerId,
    setResponseData,
    publishedForResponseResumeHash,
    topLevelSite, // Антон: откуда это значение получаем?
    addNotification,
    isQuestionResponse,
    openChatik,
}) => {
    const sendNotification = (readPercent: number | null) => {
        const vacancyId = Number(data.vacancy_id);
        const vacancyAreaId = data?.responseStatus?.shortVacancy?.area?.['@id'];

        if (checkIsItemExistsAndRemoveItInTemporaryStorage(YA_DIRECT_STORE_FIELD_BY_VACANCY_ID, data.vacancy_id)) {
            vacancyTargetingResponseCompleteExternal();
        }

        const cpaItemByVacancyId = checkIsItemExistsAndRemoveItInTemporaryStorage(
            CPA_STORE_FIELD_BY_VACANCY_ID,
            data.vacancy_id
        );
        if (cpaItemByVacancyId) {
            sendCpaLeadConversion(vacancyAreaId, vacancyId, cpaItemByVacancyId, 'VACANCY');
        }

        const cpaItemByVacancyAreaId = checkIsItemExistsInTemporaryStorage(
            CPA_STORE_FIELD_BY_VACANCY_AREA_ID,
            String(vacancyAreaId)
        );
        if (cpaItemByVacancyAreaId) {
            sendCpaLeadConversion(vacancyAreaId, vacancyId, cpaItemByVacancyAreaId, 'AREA');
        }

        if (data.sendGAAnalyticsToStorage) {
            vacancyResponseCompleteGaExternal({
                label: data.response_label as Label,
                // eslint-disable-next-line camelcase
                params: { product_id: vacancyId },
            });
            if (topLevelSite === TopLevelSite.RU) {
                Analytics.addEventPixels('B2C_VACANCY_RESPONSE_COMPLETE');
            }
        } else {
            // eslint-disable-next-line camelcase
            vacancyResponseCompleteExternal({ label: data.response_label as Label, params: { product_id: vacancyId } });
            if (topLevelSite === TopLevelSite.ZP) {
                Analytics.addEventPixels('ZP_PURCHASE');
            }
            if (topLevelSite === TopLevelSite.RU) {
                Analytics.addEventPixels('B2C_VACANCY_RESPONSE_COMPLETE');
            }
        }

        const usedResume = data.respondedWithResume;

        if (isQuestionResponse) {
            addNotification(VacancyQuestionNotification, {
                props: { openChatik },
            });
            return;
        }

        if (usedResume && publishedForResponseResumeHash === usedResume.hash && usedResume.autoHideTime?.length) {
            addNotification(resumeAutoHideNotification, {
                props: {
                    resumeHash: publishedForResponseResumeHash,
                    autoHideTime: usedResume.autoHideTime[0].string,
                },
            });
            return;
        }

        const responseData = { readPercent, topicId: data.topic_id, vacancyId };
        if (setResponseData) {
            const topic = data.responseStatus.negotiations.topicList.find((n) => String(n.id) === data.topic_id);
            const chatId = data.chat_id ? Number(data.chat_id) : undefined;
            setResponseData({ ...responseData, hasResponseLetter: !!topic?.hasResponseLetter, chatId });
            return;
        }

        if (data.askJobSearchStatus) {
            addNotification(jobSearchStatusAfterResponse, { props: { vacancyId }, uniqueType: true });
        } else {
            addNotification(vacancyResponse, { props: { ...responseData, openChatik } });
        }
    };

    if (!employerId || publishedForResponseResumeHash) {
        sendNotification(null);
    } else {
        fetcher
            .get(EMPLOYER_STATS_URL, {
                params: {
                    employerId,
                },
            })
            .then(
                ({ stats }) => sendNotification(stats.readTopicPercent),
                () => sendNotification(null)
            );
    }
};
