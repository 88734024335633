interface Params {
    topLevelDomain?: string;
    chatId?: number;
    hhtmFromLabel?: string;
}

const buildChatikUrl = ({ topLevelDomain, chatId, hhtmFromLabel }: Params): string => {
    let baseUrl = `https://chatik.${topLevelDomain || location.host}`;

    if (chatId) {
        baseUrl += `/chat/${chatId}`;
    }

    const url = new URL(baseUrl);
    hhtmFromLabel && url.searchParams.append('hhtmFromLabel', hhtmFromLabel);

    return url.toString();
};

export default buildChatikUrl;
