import { Text } from '@hh.ru/magritte-ui';
import { CheckCircleFilledSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Notification from 'lux/components/Notifications/Notification';
import { NotificationProps } from 'lux/components/Notifications/Provider/types';
import translation from 'lux/components/translation';

interface VacancyQuestionNotificationProps {
    openChatik: () => void;
}

const TrlKeys = {
    text: 'vacancy.view.notify.questionResponse.text',
    openChat: 'vacancy.view.notify.action.text',
};

const VacancyQuestionNotification: TranslatedComponent<VacancyQuestionNotificationProps & NotificationProps> = ({
    trls,
    openChatik,
    removeNotification,
}) => {
    return (
        <Notification
            removeNotification={removeNotification}
            addon={<CheckCircleFilledSize24 initial="positive" />}
            actionLabel={trls[TrlKeys.openChat]}
            onAction={() => {
                openChatik();
                removeNotification();
            }}
        >
            <Text style="contrast" typography="label-2-regular">
                {trls[TrlKeys.text]}
            </Text>
        </Notification>
    );
};

export default translation(VacancyQuestionNotification);
