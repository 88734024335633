import { useSelector } from 'lux/modules/useSelector';

interface TopicIds {
    topicId: number;
    chatId: number;
}

export interface ResumeIdResponseStatuses {
    responded: TopicIds[];
    invited: TopicIds[];
    discarded: TopicIds[];
    usedResumeIds: string[];
}

export default (vacancyId?: number): ResumeIdResponseStatuses | null => {
    const responseStatuses = useSelector((state) => state.applicantVacancyResponseStatuses);
    if (!vacancyId) {
        return null;
    }
    const responseStatus = responseStatuses[vacancyId];

    if (!responseStatus || responseStatus.usedResumeIds?.length === 0 || !responseStatus?.negotiations) {
        return null;
    }

    const { negotiations, usedResumeIds } = responseStatus;

    const responded = negotiations.topicList
        .filter(({ responded }) => responded)
        .map(({ id, chatId }) => ({ topicId: id, chatId }));
    const invited = negotiations.topicList
        .filter(({ invited }) => invited)
        .map(({ id, chatId }) => ({ topicId: id, chatId }));
    const discarded = negotiations.topicList
        .filter(({ discarded }) => discarded)
        .map(({ id, chatId }) => ({ topicId: id, chatId }));

    return {
        responded,
        invited,
        discarded,
        usedResumeIds,
    };
};
