import { useCallback, useEffect, RefObject, MouseEventHandler, MouseEvent, ReactNode, ComponentType } from 'react';
import classnames from 'classnames';

import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonKind, ButtonAppearance, ButtonScale, ButtonType } from 'bloko/blocks/button';
import Loading, { LoadingScale, LoadingColor } from 'bloko/blocks/loading';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import VacancyResponseLink from 'lux/components/VacancyResponseLink';
import translation from 'lux/components/translation';
import { UserType } from 'lux/models/userType';
import { useSelector } from 'lux/modules/useSelector';

import { scrollWithAttention, RESUME_FORM_ATTENTION_CLASS } from 'lux/components/VacancyView/scrolling';

export enum VacancyActionResponseKind {
    Main = 'main',
    AfterBody = 'afterBody',
    ResponseSummary = 'responseSummary',
    VideoVacancy = 'videoVacancy',
    Notification = 'notification',
}

const TrlKeys = {
    response: 'button.placeResponse',
};

interface VacancyActionResponseProps {
    vacancyBodyFooterNodeRef?: RefObject<HTMLDivElement>;
    resumeFormNodeRef?: RefObject<HTMLDivElement>;
    hasForceQuickResponse?: boolean;
    isSecondary?: boolean;
    responseButtonProps?: Record<string, unknown>;
    isRedesign?: boolean;
    isMagritteOnVacancyResponseExp?: boolean;
    kind?: VacancyActionResponseKind;
    forceFullSize?: boolean;
    onResponseComplete?: () => void;
    renderCustomWrapper?: (content: ReactNode) => JSX.Element;
    hideResponded?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    RespondedNode?: ComponentType<any>;
    onScrollToSignupForm?: () => void;
    onPopupCancelCallback?: () => void;
    onRespondClick?: () => void;
}

const VacancyActionResponsePlace = {
    [VacancyActionResponseKind.Main]: 'vacancy_top_button',
    [VacancyActionResponseKind.AfterBody]: 'vacancy_bottom_button',
    [VacancyActionResponseKind.ResponseSummary]: 'vacancy_response_summary',
    [VacancyActionResponseKind.VideoVacancy]: 'vacancy_video_vacancy_modal',
    [VacancyActionResponseKind.Notification]: 'vacancy_notification',
};

const VacancyActionResponse: TranslatedComponent<VacancyActionResponseProps> = ({
    trls,
    vacancyBodyFooterNodeRef,
    resumeFormNodeRef,
    hasForceQuickResponse = false,
    isSecondary = false,
    responseButtonProps,
    hideResponded = false,
    RespondedNode = Button,
    kind,
    forceFullSize,
    onResponseComplete,
    isRedesign,
    isMagritteOnVacancyResponseExp = false,
    renderCustomWrapper,
    onScrollToSignupForm,
    onPopupCancelCallback,
    onRespondClick,
}) => {
    const vacancyView = useSelector((state) => state.vacancyView);

    const contactInfo = useSelector((state) => state.vacancyView.contactInfo);
    const vacancyId = useSelector((state) => state.vacancyView.vacancyId);
    const employerId = useSelector((state) => state.vacancyView.company?.id);
    const showSignupForm = useSelector((state) => state.vacancyView.showSignupForm);
    const isBrandingPreview = useSelector((state) => state.vacancyView.isBrandingPreview);

    const responseStatus = useSelector((state) =>
        vacancyView.vacancyId ? state.applicantVacancyResponseStatuses[vacancyView.vacancyId] : undefined
    );
    const hasResponseStatus = Boolean(responseStatus && Object.keys(responseStatus).length > 1);
    const userType = useSelector((state) => state.userType);
    const doResponse = !!vacancyView.responseInfo?.doResponse;
    const showResponse = !!vacancyView.responseInfo?.showResponse;
    const { shortResume } = useSelector(({ router }) => router.location.query);
    const breakpoint = useBreakpoint();
    const isXs = breakpoint === Breakpoint.XS;
    const showContactsInfo = contactInfo && !(userType === UserType.Anonymous && isXs);
    const allowShowContactsButton = showContactsInfo || isBrandingPreview;

    const loadScrollHandler = useCallback(() => {
        if (resumeFormNodeRef?.current) {
            scrollWithAttention(resumeFormNodeRef.current, RESUME_FORM_ATTENTION_CLASS);
        }
    }, [resumeFormNodeRef]);

    const loadScrollHandlerToFooter = useCallback(() => {
        if (vacancyBodyFooterNodeRef?.current) {
            scrollWithAttention(vacancyBodyFooterNodeRef.current, RESUME_FORM_ATTENTION_CLASS);
        }
    }, [vacancyBodyFooterNodeRef]);

    useEffect(() => {
        if (resumeFormNodeRef?.current && shortResume) {
            window.addEventListener('load', loadScrollHandler);
        } else if (vacancyBodyFooterNodeRef?.current && showResponse) {
            window.addEventListener('load', loadScrollHandlerToFooter);
        }

        return () => {
            window.removeEventListener('load', loadScrollHandler);
            window.removeEventListener('load', loadScrollHandlerToFooter);
        };
    }, [
        resumeFormNodeRef,
        shortResume,
        loadScrollHandler,
        showResponse,
        vacancyBodyFooterNodeRef,
        loadScrollHandlerToFooter,
    ]);

    const renderWrapper = (content: ReactNode) => {
        if (renderCustomWrapper) {
            return renderCustomWrapper(content);
        }
        return (
            <div
                className={classnames('vacancy-action', {
                    'vacancy-action_full-size':
                        forceFullSize || (allowShowContactsButton && kind === VacancyActionResponseKind.Main),
                    'vacancy-action_full-size-redesigned':
                        isRedesign &&
                        (forceFullSize || (allowShowContactsButton && kind === VacancyActionResponseKind.Main)),
                    'vacancy-action_stretched':
                        !forceFullSize && !allowShowContactsButton && kind === VacancyActionResponseKind.Main,
                    'vacancy-action_stretched-redesigned': isRedesign,
                    'vacancy-action_after-body':
                        kind !== VacancyActionResponseKind.Main && kind !== VacancyActionResponseKind.Notification,
                    'vacancy-action_after-body-no-margin': isMagritteOnVacancyResponseExp,
                })}
            >
                {content}
            </div>
        );
    };

    if (isBrandingPreview) {
        return renderWrapper(
            <Button type={ButtonType.Button} scale={ButtonScale.Large} disabled kind={ButtonKind.Success} stretched>
                {trls[TrlKeys.response]}
            </Button>
        );
    }

    if (!vacancyId) {
        return null;
    }

    if (userType === UserType.Applicant && !hasResponseStatus) {
        return null;
    }

    const onButtonClick = (event: MouseEvent<Element>, onClickCallback: MouseEventHandler<Element>) => {
        event.preventDefault();
        onRespondClick?.();

        onClickCallback(event);
    };

    return renderWrapper(
        <VacancyResponseLink
            vacancyId={vacancyId}
            employerId={employerId}
            doResponse={!isSecondary && doResponse}
            hasForceQuickResponse={hasForceQuickResponse}
            render={(onClickCallback) => (
                <Button
                    stretched
                    scale={isRedesign ? ButtonScale.Large : undefined}
                    Element={Link}
                    rel="nofollow"
                    to={`/applicant/vacancy_response?vacancyId=${vacancyId || ''}${
                        employerId ? `&employerId=${employerId}` : ''
                    }`}
                    data-qa="vacancy-response-link-top"
                    loading={
                        responseStatus?.isFetching &&
                        !responseStatus?.isFetchingWithQuestionResponse && (
                            <Loading initial={LoadingColor.White} scale={LoadingScale.Small} />
                        )
                    }
                    disabled={!!responseStatus?.isFetching}
                    onClick={(event) => onButtonClick(event, onClickCallback)}
                    kind={ButtonKind.Success}
                    {...responseButtonProps}
                >
                    {trls[TrlKeys.response]}
                </Button>
            )}
            renderResponded={({ text, onClick }) =>
                !hideResponded && (
                    <RespondedNode
                        Element="button"
                        scale={isRedesign ? ButtonScale.Large : undefined}
                        kind={
                            isRedesign || kind === VacancyActionResponseKind.VideoVacancy
                                ? ButtonKind.Success
                                : undefined
                        }
                        onClick={onClick}
                        data-qa="vacancy-response-link-view-topic"
                        {...(RespondedNode === Button && { stretched: true })}
                        {...(responseButtonProps || { appearance: ButtonAppearance.Outlined })}
                    >
                        {text}
                    </RespondedNode>
                )
            }
            scrollToSignupForm={showSignupForm}
            vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
            enableRelocationWarning
            onResponseComplete={onResponseComplete}
            onScrollToSignupForm={onScrollToSignupForm}
            onPopupCancelCallback={onPopupCancelCallback}
            place={kind && VacancyActionResponsePlace[kind]}
        />
    );
};

export default translation(VacancyActionResponse);
