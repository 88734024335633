import { type ReactElement, Fragment, useState, useRef } from 'react';

import { Card, Divider, Link, VSpacingContainer, useCollapsible } from '@hh.ru/magritte-ui';
import { ChevronUpOutlinedSize16, ChevronDownOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { FormItem } from 'bloko/blocks/form';
import Text from 'bloko/blocks/text';
import { type TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import translation from 'lux/components/translation';
import useExperiment from 'lux/hooks/useExperiment';
import type { ResponseStatusResume, ShortVacancy } from 'lux/models/applicantVacancyResponseStatuses';

import VacancyResponseFormResume from 'lux/components/VacancyResponseForm/Resume';
import { RESUME_SELECTOR_LIMIT_TO_COLLAPSE } from 'lux/components/VacancyResponseForm/constants';

const TrlKeys = {
    resumesTitle: 'vacancy.response.popup.resumes',
    questionResumesTitle: 'vacancy.questionResponse.popup.resumes',
    hiddenResumes: 'vacancy.response.popup.hidden.resumes',
    showMore: 'vacancy.response.popup.resumes.action.showMore',
    showLess: 'vacancy.response.popup.resumes.action.showLess',
};

interface ResumeSelectorProps {
    responseStatus: ShortVacancy;
    resumes: ResponseStatusResume[];
    selectedResume: ResponseStatusResume;
    isQuestionResponse?: boolean;
    setSelectedResume: (value: ResponseStatusResume) => void;
}

const ResumeSelector: TranslatedComponent<ResumeSelectorProps> = ({
    trls,
    responseStatus,
    resumes,
    selectedResume,
    isQuestionResponse,
    setSelectedResume,
}) => {
    const vacancy = responseStatus.shortVacancy;
    const hasHiddenResumes = responseStatus.hiddenResumeIds.length > 0;
    const isSingleResume = resumes.length === 1;

    const isMagritte = useExperiment('magritte_on_vacancy_response');
    const isCollapsableItems = resumes.length > RESUME_SELECTOR_LIMIT_TO_COLLAPSE;
    const refRest = useRef<HTMLDivElement>(null);

    // We store resumes as list with preselected resume as first item to always show it on top of the list.
    const sortedResumes = useRef(
        resumes.reduce((result, resume) => {
            if (resume.id === selectedResume.id) {
                result.unshift(resume);
            } else {
                result.push(resume);
            }

            return result;
        }, [] as ResponseStatusResume[])
    );

    // As we store selected resume on top of the list we can display list collapsed by default,
    // so selected value will never be collapsed.
    const [isCollapsed, setIsCollapsed] = useState(true);
    const { collapsibleClasses } = useCollapsible(refRest, !isCollapsed);

    const itemRenderer = (
        resume: ResponseStatusResume,
        index: number
    ): ReactElement<typeof VacancyResponseFormResume> => (
        <Fragment key={resume.hash}>
            {/* We use `Divider` component here instead of `showDivider` prop of `Cell` as last one differs from design. */}
            {index >= 1 && <Divider />}
            <VacancyResponseFormResume
                key={resume.hash}
                resume={resume}
                single={isSingleResume}
                hidden={responseStatus.hiddenResumeIds.includes(String(resume.id))}
                selected={resume.id === selectedResume.id}
                onSelect={() => setSelectedResume(resume)}
                visibility={responseStatus.resumeVisibility?.[resume.id]}
                vacancy={vacancy}
            />
        </Fragment>
    );

    if (isMagritte) {
        return (
            <Card
                padding={isSingleResume ? 16 : 0}
                borderRadius={isSingleResume ? 16 : 0}
                showBorder={isSingleResume}
                stretched
            >
                {hasHiddenResumes && <FormItem>{trls[TrlKeys.hiddenResumes]}</FormItem>}

                <div>{sortedResumes.current.slice(0, RESUME_SELECTOR_LIMIT_TO_COLLAPSE).map(itemRenderer)}</div>
                {isCollapsableItems && (
                    <VSpacingContainer default={8}>
                        <div className={collapsibleClasses} ref={refRest}>
                            <Divider />
                            {sortedResumes.current.slice(RESUME_SELECTOR_LIMIT_TO_COLLAPSE).map(itemRenderer)}
                        </div>
                        <Link
                            Element="button"
                            iconRight={isCollapsed ? ChevronDownOutlinedSize16 : ChevronUpOutlinedSize16}
                            disabled={
                                !isCollapsed &&
                                sortedResumes.current.findIndex((resume) => resume.id === selectedResume.id) >=
                                    RESUME_SELECTOR_LIMIT_TO_COLLAPSE
                            }
                            onClick={(event) => {
                                event.preventDefault();
                                setIsCollapsed(!isCollapsed);
                            }}
                        >
                            {isCollapsed
                                ? formatToReactComponent(trls[TrlKeys.showMore], {
                                      '{0}': resumes.length - RESUME_SELECTOR_LIMIT_TO_COLLAPSE,
                                  })
                                : trls[TrlKeys.showLess]}
                        </Link>
                    </VSpacingContainer>
                )}
            </Card>
        );
    }

    return (
        <>
            <div className="vacancy-response-popup-subtitle">
                <Text strong>{trls[isQuestionResponse ? TrlKeys.questionResumesTitle : TrlKeys.resumesTitle]}</Text>
            </div>
            <div className="vacancy-response-popup-resume-list" data-qa="vacancy-response-resume-list">
                {hasHiddenResumes && <FormItem>{trls[TrlKeys.hiddenResumes]}</FormItem>}
                {resumes.map((resume) => {
                    return (
                        <VacancyResponseFormResume
                            resume={resume}
                            key={resume.hash}
                            selected={resume.id === selectedResume.id}
                            single={isSingleResume}
                            hidden={responseStatus.hiddenResumeIds.includes(String(resume.id))}
                            onSelect={() => setSelectedResume(resume)}
                            visibility={responseStatus.resumeVisibility?.[resume.id]}
                            vacancy={vacancy}
                        />
                    );
                })}
            </div>
        </>
    );
};

export default translation(ResumeSelector);
