import Analytics from '@hh.ru/analytics-js';
import { Banner, Text } from '@hh.ru/magritte-ui';
import Gap from 'bloko/blocks/gap';
import Information from 'bloko/blocks/information';
import { type ElementSpyInstance } from 'bloko/common/elementSpy';
import { type TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import ElementShownAnchor from 'lux/components/ElementShownAnchor';
import translation from 'lux/components/translation';
import useExperiment from 'lux/hooks/useExperiment';
import { type ResponseStatusResume } from 'lux/models/applicantVacancyResponseStatuses';
import { ResumeInconsistencyType, type ResumeInconsistency } from 'lux/models/vacancyResponsePopup';

interface InconsistenciesProps {
    inconsistencies: { resume: ResumeInconsistency[] };
    selectedResumeId: string;
    vacancyId: number;
    resumes: ResponseStatusResume[];
}

const TrlKeys = {
    title: 'vacancy.response.popup.inconsistency.title',
    caption: 'vacancy.response.popup.inconsistency',
    lang: 'vacancy.response.popup.inconsistency.lang',
    inconsistencies: {
        [ResumeInconsistencyType.Language]: 'vacancy.response.popup.inconsistency.language',
        [ResumeInconsistencyType.Area]: 'vacancy.response.popup.inconsistency.area',
        [ResumeInconsistencyType.Distance]: 'vacancy.response.popup.inconsistency.distance',
        [ResumeInconsistencyType.Experience]: 'vacancy.response.popup.inconsistency.experience',
    },
    emptyTitle: 'resume.title.empty',
};

const sendEventInconsistenciesShown = (
    element: HTMLElement,
    params: {
        resumeId: string;
        vacancyId: number;
        type: ResumeInconsistency['inconsistencies']['inconsistency'][0]['type'];
    }
): ElementSpyInstance =>
    Analytics.sendHHEventElementShown(element, {
        name: 'vacancy_response_inconsistency',
        ...params,
    });

const Inconsistencies: TranslatedComponent<InconsistenciesProps> = ({
    inconsistencies,
    selectedResumeId,
    resumes,
    vacancyId,
    trls,
}) => {
    const isMagritte = useExperiment('magritte_on_vacancy_response');

    const resumeInconsistencies = inconsistencies?.resume?.find(
        (resume) => String(resume.id) === String(selectedResumeId)
    );

    const resumeInconsistency = resumeInconsistencies?.inconsistencies?.inconsistency?.[0];

    if (!inconsistencies?.resume || !resumeInconsistencies || !resumeInconsistency) {
        return null;
    }

    const selectedResume = resumes.find((resume) => String(resume.id) === String(selectedResumeId));

    if (!selectedResume) {
        return null;
    }

    // тайтла может не быть, если это незавершенное дублированное резюме
    const resumeTitle = selectedResume.title[0]?.string ?? trls[TrlKeys.emptyTitle];

    if (isMagritte) {
        return (
            <ElementShownAnchor
                fn={sendEventInconsistenciesShown}
                resumeId={selectedResumeId}
                vacancyId={vacancyId}
                type={resumeInconsistency?.type}
            >
                <Banner
                    title={
                        <Text style="primary" typography="title-5-semibold">
                            {trls[TrlKeys.title]}
                        </Text>
                    }
                    content={
                        <Text typography="paragraph-2-regular">
                            {format(trls[TrlKeys.inconsistencies[resumeInconsistency.type]], {
                                '{0}': `${resumeInconsistency.languageTrl} ${trls[TrlKeys.lang]}`,
                                '{1}': resumeTitle,
                            })}
                        </Text>
                    }
                    style="negative"
                    showClose={false}
                    stretched
                    visible
                />
            </ElementShownAnchor>
        );
    }

    return (
        <Gap top>
            <ElementShownAnchor
                fn={sendEventInconsistenciesShown}
                resumeId={selectedResumeId}
                vacancyId={vacancyId}
                type={resumeInconsistency?.type}
            >
                <Information>
                    <strong>{`${trls[TrlKeys.caption]} `}</strong>
                    {format(trls[TrlKeys.inconsistencies[resumeInconsistency.type]], {
                        '{0}': `${resumeInconsistency.languageTrl} ${trls[TrlKeys.lang]}`,
                        '{1}': resumeTitle,
                    })}
                </Information>
            </ElementShownAnchor>
        </Gap>
    );
};

export default translation(Inconsistencies);
